import moment from "moment";
import ApiService from "../services/ApiService";
interface Season {
  id: number;
  name: string;
  created_by: string;
}

const CancelToken = ApiService.getInstance().CancelToken;
let cancelRequest;
const Seasons: Array<Season> = [];

const deleteSeason = function (item: Season) {
  return ApiService.delete(`season/${item.id}/`);
};

const addSeason = function (item, file, id) {
  const formData = new FormData();
  delete item.image;
  for (const key in item) {
    formData.append(key, item[key]);
  }
  if (file && file[0]) {
    formData.append("image", file[0]);
  }
  if (id) {
    return ApiService.put(`season/${id}/`, formData);
  } else {
    return ApiService.post("season/", formData);
  }
};

const exportSeasonData = function (ids) {
  return new Promise((res, rej) => {
    ApiService.csvFile("export/season/?season_id=" + ids, "season-data").then(
      (data) => res(data)
    );
  });
};
const exportPDFSeasons = function (ids) {
  return new Promise((res, rej) => {
    ApiService.pdfFile("pdf/season/?season_id=" + ids).then((data) =>
      res(data)
    );
  });
};
const seasonObjClone = function () {
  return {
    id: 0,
    name: "",
    created_by: "",
  };
};
const jsonToInterface = function (item: any) {
  const newItem: Season = seasonObjClone();
  newItem.id = item.id;
  newItem.name = item.name;
  newItem.created_by = item.created_by;
  return newItem;
};
const getSeasonById = function (id) {
  return new Promise((res, rej) => {
    ApiService.get(`season/${id}`).then(function (response) {
      res(jsonToInterface(response.data));
    });
  });
};

const getSeasons = function (
  pageNumber = 1,
  searchData,
  status = "",
  user = ""
) {
  return new Promise((res, rej) => {
    const limit = 10;
    const queryObj = {
      page: pageNumber,
      limit: limit,
      offset: (pageNumber - 1) * limit,
      search: searchData.name || "",
      status: status,
      created_by: user,
    };
    console.log(queryObj);
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(`season/?${queryStr}`, cancelRequest)
      .then(function (response) {
        const resObj: any = {
          count: 0,
          data: [],
        };
        resObj.count = response.data.count;
        if (response.data && response.data.results) {
          resObj.data = response.data.results.map((item) => {
            return jsonToInterface(item);
          });
        }
        console.log("resObj", resObj);
        res(resObj);
      })
      .catch((error) => rej(error));
  });
};

const jsonToInterfaceSeason = jsonToInterface;
const getEmptyObjOfSeason = seasonObjClone;
export {
  Season,
  getSeasons,
  deleteSeason,
  addSeason,
  getSeasonById,
  jsonToInterfaceSeason,
  seasonObjClone,
  exportSeasonData,
  exportPDFSeasons,
  getEmptyObjOfSeason,
};

export default Seasons;
