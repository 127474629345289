import ApiService from "../services/ApiService";
interface User {
  id: number;
  name: string;
  email: string;
  role: string;
  role_details: string;
  roles: string;
  password: string;
  username: string;
}
const UserRole = {
  MARKETING_HEAD: "MARKETING HEAD",
  ACCOUNTANT: "ACCOUNTANT",
  PRODUCTION_MANAGER_1: "PRODUCTION MANAGER 1",
  PRODUCTION_MANAGER_1_ASSISTANT: "PRODUCTION MANAGER 1 ASSISTANT",
  PRODUCTION_MANAGER_2: "PRODUCTION MANAGER 2",
  FUSING_DEPARTMENT: "FUSING DEPARTMENT",
  DESIGNER: "DESIGNER",
};
const UserRoleList = Object.keys(UserRole).map((key) => ({
  value: key,
  label: UserRole[key],
}));
UserRole["ADMIN"] = "ADMIN";
const Users: Array<User> = [];

const CancelToken = ApiService.getInstance().CancelToken;
let cancelRequest;

const userObjClone = function () {
  return {
    id: 0,
    name: "",
    email: "",
    role: "",
    role_details: "",
    roles: "",
    password: "",
    username: "",
  };
};
const jsonToInterface = function (item: any) {
  const newItem: User = {
    id: 0,
    name: "",
    email: "",
    role: "",
    role_details: "",
    roles: "",
    password: "",
    username: "",
  };
  newItem.id = item.id;
  newItem.name = item.name;
  newItem.email = item.email;
  newItem.role = item.role;
  newItem.role_details = item.role_details;
  newItem.roles = item.roles;
  newItem.password = item.password;
  newItem.username = item.username;
  return newItem;
};
const deleteUser = function (item: User) {
  return ApiService.delete(`users/${item.id}/`);
};

const addUser = function (item, id) {
  if (id) {
    delete item.password;
    return ApiService.put(`users/details/${id}/`, item);
  } else {
    delete item.id;
    item.username = item.email;
    item.roles = item.role;
    item.role = "staff";
    return ApiService.post("users/", item);
  }
};
const changePWD = function (item: any, id: any) {
  return ApiService.put(`users/password/change/${id}/`, {
    new_password: item.password,
  });
};

const getUsers = function (pageNumber = 1, name = "", limit = 10) {
  return new Promise((res, rej) => {
    const queryObj = {
      page: pageNumber,
      limit: limit,
      offset: (pageNumber - 1) * limit,
      search: name,
    };
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(
      `users/details/?${queryStr}`,
      cancelRequest
    ).then(function (response) {
      const resObj: any = {
        data: [],
      };
      if (response.data && response.data.results) {
        resObj.count = response.data.count;
        resObj.data = response.data.results.map((item) => {
          return jsonToInterface(item);
        });
      }
      console.log("resObj", resObj);
      res(resObj);
    });
  });
};
const getPermissionByUser = function () {
  return new Promise((res, rej) => {
    ApiService.get(`users/permission`).then(function (response) {
      const resObj: any = {
        data: [],
      };
      if (response.data) {
        res(jsonToInterface(response.data));
      }
    });
  });
};
const getUserById = function (id) {
  return new Promise((res, rej) => {
    ApiService.get(`users/details/${id}`).then(function (response) {
      const resObj: any = {
        data: [],
      };
      if (response.data) {
        res(jsonToInterface(response.data));
      }
    });
  });
};
export {
  User,
  getUsers,
  deleteUser,
  addUser,
  getUserById,
  UserRole,
  UserRoleList,
  userObjClone,
  getPermissionByUser,
  changePWD,
};

export default Users;
